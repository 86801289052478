import { template as template_824967459b2a4fa0a753b17ed0991686 } from "@ember/template-compiler";
const FKLabel = template_824967459b2a4fa0a753b17ed0991686(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_1288b5df293040af9788be2fa51ec795 } from "@ember/template-compiler";
const WelcomeHeader = template_1288b5df293040af9788be2fa51ec795(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_613d3424d05c4137b4f7a27dbd0e9a16 } from "@ember/template-compiler";
const FKControlMenuContainer = template_613d3424d05c4137b4f7a27dbd0e9a16(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

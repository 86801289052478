import { template as template_8b47dfacd8354133bbe5ce1835773921 } from "@ember/template-compiler";
const FKText = template_8b47dfacd8354133bbe5ce1835773921(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
